import React from "react";
import styled from "styles/theme";

interface IProps {
  children?: React.ReactNode;
}

export default ({ children }: IProps) => {
  return <Layout>{children}</Layout>;
};

const Layout = styled.div``;
