import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styles/theme";
import moment from "moment";

interface IProps {
  data?: IWsData[];
  event?: "ping" | "summaries";
  timestamp: number;
}

interface IWsData {
  accounting_currency: "USD" | "JPY" | "KRW" | "CNY";
  pnl: number;
  position: number;
  strategy_group: string;
  strategy_name: string;
  trader: string;
  turnover: number;
}

interface IStyle {
  positive: number;
}

let wsData: IProps;

let ws: any;

let dataHook: any;

const connectWebSocket = () => {
  // 소켓 연동
  ws = new WebSocket("ws://15.164.99.35:3002");
  ws.onopen = () => {
    console.log("onopen");
    ws.send(JSON.stringify({}));
  };

  ws.onmessage = (e: any) => {
    wsData = JSON.parse(e.data);
    console.log(wsData, "wsData");
    if (dataHook) {
      wsData.data && dataHook.setTrade(wsData);
      dataHook.setTime(new Date().getTime());
    }
  };

  ws.onclose = () => {
    console.log("onclose");
    setTimeout(() => connectWebSocket(), 5000);
  };

  ws.onerror = (err: any) => {
    console.log("error", err);
    ws.close();
  };

  console.log("connect?");
};

connectWebSocket();

const Home = (props: any) => {
  const [trade, setTrade] = useState<IProps>(wsData);
  const [time, setTime] = useState<number>(new Date().getTime());

  const {
    location: { search }
  } = props;

  const filterOpt =
    search.length > 0 &&
    search
      .replace("?", "")
      .split("&")
      .reduce((acc: any, cur: string) => {
        const tempData = cur.split("=");
        acc[tempData[0]] = tempData[1];

        return acc;
      }, {});

  let totalPnl = 0;
  let totalTurnover = 0;

  dataHook = {
    setTrade: setTrade,
    setTime: setTime
  };

  const headTh = ["Trader", "strategy name", "strategy group", "currency", "position", "pnl", "turnover"];

  let row = trade && trade.data && trade.data;

  if (filterOpt) {
    if (filterOpt.strategy_group) {
      row = row && row.filter(item => item.strategy_group.indexOf(filterOpt.strategy_group) > -1);
    }

    if (filterOpt.accounting_currency) {
      row = row && row.filter(item => item.accounting_currency.indexOf(filterOpt.accounting_currency.toUpperCase()) > -1);
    }
  }

  if (row) {
    row.forEach(item => {
      totalPnl += item.pnl;
      totalTurnover += item.turnover;
    });
  }

  return (
    <Wrap>
      {trade ? (
        <Fragment>
          <Tab>
            <Link to="/">All</Link>
          </Tab>
          <Tab>
            group:
            <Link to="/?strategy_group=strategy_group_1">group1</Link>
            <Link to="/?strategy_group=strategy_group_2">group2</Link>
            <Link to="/?strategy_group=strategy_group_3">group3</Link>
          </Tab>
          <Tab>
            currency:
            <Link to="/?accounting_currency=USD">USD</Link>
            <Link to="/?accounting_currency=KRW">KRW</Link>
            <Link to="/?accounting_currency=JPY">JPY</Link>
            <Link to="/?accounting_currency=CNY">CNY</Link>
          </Tab>
          <Tab>
            group + currency:
            <Link to="/?strategy_group=strategy_group_1&amp;accounting_currency=USD">group1 + USD</Link>
            <Link to="/?strategy_group=strategy_group_2&amp;accounting_currency=USD">group2 + USD</Link>
            <Link to="/?strategy_group=strategy_group_3&amp;accounting_currency=USD">group3 + USD</Link>
            <Link to="/?strategy_group=strategy_group_1&amp;accounting_currency=KRW">group1 + KRW</Link>
            <Link to="/?strategy_group=strategy_group_2&amp;accounting_currency=KRW">group2 + KRW</Link>
            <Link to="/?strategy_group=strategy_group_3&amp;accounting_currency=KRW">group3 + KRW</Link>
            <Link to="/?strategy_group=strategy_group_1&amp;accounting_currency=JPY">group1 + JPY</Link>
            <Link to="/?strategy_group=strategy_group_2&amp;accounting_currency=JPY">group2 + JPY</Link>
            <Link to="/?strategy_group=strategy_group_3&amp;accounting_currency=JPY">group3 + JPY</Link>
            <Link to="/?strategy_group=strategy_group_1&amp;accounting_currency=CNY">group1 + CNY</Link>
            <Link to="/?strategy_group=strategy_group_2&amp;accounting_currency=CNY">group2 + CNY</Link>
            <Link to="/?strategy_group=strategy_group_3&amp;accounting_currency=CNY">group3 + CNY</Link>
          </Tab>
          <Timestamp>
            Time: <strong>{moment(time).format("YYYY/MM/DD-HH:mm:ss")}</strong>
          </Timestamp>
          <TotalPnl positive={totalPnl}>
            Total pnl: <strong>{totalPnl}</strong>
          </TotalPnl>
          <TotalTurnOver>
            Total turnover: <strong>{totalTurnover}</strong>
          </TotalTurnOver>
          <Table>
            <thead>
              <tr>
                {headTh.map((item, index) => (
                  <Th key={index}>{item}</Th>
                ))}
              </tr>
            </thead>
            <tbody>
              {row && row.length > 0 ? (
                row.map((item, index) => (
                  <tr key={index}>
                    <Td>{item.trader}</Td>
                    <Td>{item.strategy_name}</Td>
                    <Td>{item.strategy_group}</Td>
                    <Td>{item.accounting_currency}</Td>
                    <Td>{item.position}</Td>
                    <Td>
                      <Pnl positive={item.pnl}>{item.pnl}</Pnl>
                    </Td>
                    <Td>{item.turnover}</Td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className={"center"}>
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Fragment>
      ) : (
        "Loading"
      )}
    </Wrap>
  );
};

export default Home;

const Wrap = styled.div`
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;

  & > strong + strong {
    margin-top: 5px;
  }
`;

const Tab = styled.div`
  + div {
    margin-top: 10px;
  }

  a {
    display: inline-block;
    margin: 5px;
    padding: 5px;
    border: 1px solid #bbb;
    border-radius: 5px;
  }
`;

const Timestamp = styled.div`
  display: block;
`;

const TotalPnl = styled.div<IStyle>`
  display: block;
  margin-top: 20px;

  & > strong {
    color: ${p => (p.positive > 0 ? "green" : p.positive < 0 ? "red" : "inherit")};
  }
`;

const TotalTurnOver = styled.div`
  display: block;
  margin-top: 10px;
`;

const Table = styled.table`
  margin-top: 20px;
  border: 1px solid #ccc;

  .center {
    padding: 10px;
    text-align: center;
  }
`;

const Th = styled.th`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left;
`;
const Td = styled.td`
  padding: 10px;
`;

const Pnl = styled.strong<IStyle>`
  color: ${p => (p.positive > 0 ? "green" : p.positive < 0 ? "red" : "inherit")};
`;
