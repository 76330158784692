import { css } from "@emotion/core";

const FontFaceNum = css`
  font-family: "Noto Sans KR", "Noto Sans", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", "dotum", Helvetica, Arial, Verdana, Tahoma, sans-serif;
`;

const FontSetNormal = css`
  font: 14px/1.2 "Noto Sans KR", "Noto Sans", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", "dotum", Helvetica, Arial, Verdana, Tahoma, sans-serif;
`;

const GlobalCss = css`
  html {
    width: 100%;
    height: 100%; /*-ms-touch-action: none;*/
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  body {
    position: relative;
    width: 100%;
    height: 100%;
    color: #555;
    ${FontSetNormal}
    /*word-break: break-all; word-wrap: break-word; word-break: keep-all;*/ 
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    overflow-x: auto;
    overflow-y: scroll;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font: 14px/1.2 "Noto Serif KR", "Noto Serif", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", "dotum", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }
  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  fieldset,
  input,
  textarea,
  blockquote,
  th,
  td,
  p,
  button,
  span {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: transparent;
  }
  fieldset,
  img,
  abbr,
  acronym {
    border: 0 none;
  }
  img {
    vertical-align: top;
  }
  legend {
    *margin-left: -7px;
  }
  legend.hidden {
    position: absolute;
    height: 0;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  address,
  caption,
  cite,
  code,
  dfn,
  em,
  var {
    font-style: normal;
    font-weight: normal;
  }
  ol,
  ul {
    list-style: none;
  }
  q:before,
  q:after {
    content: "";
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  caption {
    height: 0;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    text-indent: -10000px;
  }
  input:focus,
  a:focus,
  button:focus {
    /* outline: #000 dotted 1px !important; outline-offset: -1px;  */
    outline: 0 none;
  }
  textarea:focus {
    /* outline: none !important; */
  }
  a {
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  a:focus {
    transition: outline 0.2s;
    -webkit-transition: outline 0.2s;
  }
  a:focus,
  a:hover {
    color: #4a90e2;
    text-decoration: none;
  }
  button {
    position: relative;
    border: 0 none;
    background: none;
  }
  /* button span,
  button:active span,
  button:focus span,
  button:active img,
  button:focus img {
    position: relative;
    top: 0;
    left: 0;
  } */
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  label,
  button,
  input,
  select,
  textarea {
    color: #5b5b5b;
    line-height: 1.2;
    font-size: inherit;
    vertical-align: middle;
    ${FontFaceNum};
  }
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    cursor: pointer;
    overflow: visible;
    -webkit-appearance: button;
  }
  select {
    border-radius: 0;
  }
  textarea {
    overflow: auto;
    resize: none;
  }
  textarea[readonly="readonly"]:focus {
    border-color: #bfbfbf;
  }
  input[type="checkbox"],
  input[type="radio"] {
    width: 13px;
    height: 13px;
    box-sizing: border-box;
    border-radius: 0;
    cursor: pointer;
  }
  input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: border-box;
  }
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #a1a1a1;
    opacity: 1;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #a1a1a1;
    opacity: 1;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #a1a1a1;
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a1a1a1;
  }
  :-ms-textarea-placeholder {
    /* Internet Explorer 10-11 */
    color: #a1a1a1;
  }
  input::-ms-clear {
    display: none;
  } /* ie clear button */
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  label {
    box-sizing: border-box;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  label:active {
    -webkit-appearance: none;
  }
  label:focus {
    outline: none;
  }
  /* html5 태그*/
  header,
  footer,
  article,
  section,
  aside,
  details,
  figure,
  figcaption,
  nav,
  hgroup {
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  audio[controls],
  canvas,
  video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
  }
  /* 건너뛰기 네비게이션 */
  #skip-nav {
    width: 100%;
    height: 0;
    text-align: center;
    overflow: hidden;
  }
  #skip-nav a {
    color: #000;
    font-size: 14px;
    font-weight: 800;
    float: left;
  }
  #skip-nav a:focus,
  #skip-nav a:hover,
  #skip-nav a:active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-decoration: underline;
    background-color: #fff;
    overflow: visible;
    z-index: 9999;
  }
  svg {
    fill: currentColor;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    outline: 0;
  }

  #modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000000;
  }
`;

export { FontFaceNum, FontSetNormal };

export default GlobalCss;
