import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import Layout from "styles/layout/default";
import Home from "pages/index";

const baseUrl = process.env.PUBLIC_URL;
const history = createBrowserHistory({ basename: baseUrl });

interface IProps {
  path: string;
  component: any;
  name: string;
}

const routes = [
  {
    path: "/",
    component: Home,
    name: "home"
  }
];

const BindRoute = ({ component: Component, path, ...rest }: IProps) => {
  return <Route path={path} {...rest} render={props => <Component {...rest} {...props} />} />;
};

const Routes = (props: any) => (
  <Layout>
    <Switch>
      {routes.map(({ path, component, name }: IProps, index: number) => (
        <BindRoute {...props} exact={true} path={path} component={component} name={name} key={index} />
      ))}
    </Switch>
  </Layout>
);

const App = () => (
  <BrowserRouter>
    <Routes history={history} />
  </BrowserRouter>
);

export default App;
