import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Global, css } from "@emotion/core";
import reset from "styles/theme/reset";
import Route from "route";

const initStyles = css`
  ${reset}
`;

const rootElement = document.getElementById("root") as HTMLElement;

ReactDOM.render(
  <Fragment>
    <Global styles={initStyles} />
    <Route />
  </Fragment>,
  rootElement
);
